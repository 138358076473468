import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
// import { animateScroll as scroll } from "react-scroll";

import { SUBSCRIBE } from "./apiConstants";
function Footer() {
  const currentDate = moment(); // Get the current date
  const year = currentDate.format("YYYY");

  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // user API endpoint to post the data

    axios
      .post(SUBSCRIBE, { email })
      .then((res) => {
        alert("Thank you for subscribing");
        // reset the email
        setEmail("");
      })
      .catch((err) => {
        if (err.response) {
          // The request was made and the server responded with Link status code
          // Extract the error message from the response data
          const errorMessage = err.response.data.email[0];
          alert(`Error: ${errorMessage}`);
        } else {
          // Error occurred before the request was made or no response was received
          alert("Sorry, something went wrong");
        }
      });
  };

  // const handleTabClick = (sectionId) => {
  //   scroll.scrollTo(sectionId, {
  //     smooth: true,
  //     duration: 300,
  //     offset: -50, // Adjust the offset value as needed
  //   });
  // };

  return (
    <>
      {/* Footer */}
      <footer
        id="footer"
        className="text-center text-white my-1"
        style={{ background: "rgba(7, 7, 61, 0.85)" }}
      >
        {/* Grid container */}
        <div className="container p-4">
          {/* Section: Social media */}
          <section className="mb-4 ">
            {/* Facebook */}
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.facebook.com/ELiNepal/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
            

            {/* Instagram */}
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.instagram.com/exquisitelearninginnepal/?fbclid=IwAR19TERyYnetLrSF9w8HxQtNyhBcO5gwoxMcVXuYqNL61b6mAV2xvPv1yJw"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
            {/* Linkedin */}
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.linkedin.com/in/exquisite-learning-in-nepal-ba7a0a28a/"
              target="_blank"
              role="button"
              rel="noreferrer"
    
            >
              <i className="fab fa-linkedin-in" />
            </a>
             
          </section>
          {/* Section: Social media */}
          {/* Section: Form */}
          <section className="">
            <form action="">
              {/*Grid row*/}
              <div className="row d-flex justify-content-center">
                {/*Grid column*/}
                <div className="col-auto">
                  <p className="pt-2">
                    <strong>Sign up for my   new blogs</strong>
                  </p>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-md-6 col-12">
                  {/* Email input */}
                  <div className="form-outline form-white mb-4">
                    <input
                      type="email"
                      id="form5Example2"
                      className="form-control"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <label
                      className="form-label"
                      htmlFor="form5Example2"
                    ></label>
                  </div>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-auto">
                  {/* Submit button */}
                  <button
                    type="submit"
                    className="btn btn-outline-light mb-4"
                    onClick={handleSubmit}
                  >
                    Subscribe
                  </button>
                </div>
                {/*Grid column*/}
              </div>
              {/*Grid row*/}
            </form>
          </section>
          {/* Section: Form */}

          {/* Section: Links */}
        </div>
        {/* Grid container */}
        {/* Copyright */}
        <div
          className="text-center p-3 d-flex justify-content-center flex-wrap align-items-center "
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          <div>
            © {year} Copyright:Exquisite learning in Nepal (ELiN){" "}
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/ram-udgar-yadav-26a1041a6/"
              className=" btn text-light  "
            >
              @developer
            </Link>
          </div>
        </div>

        {/* Copyright */}
      </footer>
      {/* Footer */}
      {/* End of .container */}
    </>
  );
}

export default Footer;
