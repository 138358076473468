import React, { useState } from "react";
import styled from "styled-components";
import "../assets/css/tab.css";

const Tab = styled.button`
  font-size: 1rem;
  padding: 10px 60px;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: transparent;
  color: rgba(5, 5, 74, 0.971);
  font-family: "poppins", sans-serif;
  font-weight: 600;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2.5px solid orange;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 1024px) {
    width: (100% - 20px);
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

function TabGroup({ tabs, category }) {
  const [active, setActive] = useState(tabs[0].type);

  const handleTabClick = (type) => {
    setActive(type);
  };

  return (
    <div className="container-fluid tab">
      <ButtonGroup className="btnClass mt-1 shadow p-1 bg-white  rounded mb-4">
        {tabs.map((tab) => (
          <Tab
            key={tab.type}
            active={active === tab.type}
            onClick={() => handleTabClick(tab.ftype)}
          >
            {tab.type}
          </Tab>
        ))}
      </ButtonGroup>

      <p className="text-uppercase fw-semibold fs-5">
        {category}/ {active}
      </p>
      {/* Render content based on the selected tab */}
      {tabs.find((tab) => tab.type === active)?.content}
    </div>
  );
}

export default TabGroup;
