import React from "react";
import { Card } from "react-bootstrap";
import privacyPolicy from "../assets/images/privacyPolicy .jpg";
import { ToogleContent } from "./toogleContent";
import "../assets/css/terms.css";

function PrivacyPolicy() {
  return (
    <>
      <div className=" ">
        <Card className="mt-1 shadow  border border-warning  border-5">
          <Card.Img
            variant="top"
            className=" shadow privacy-img"
            style={{ height: "18rem" }}
            src={privacyPolicy}
          />
        </Card>

        {/* Privacy Policy section */}

        <div className="container" id="privacy">
          <h3
            className="text-start my-5 mb-4 fw-bold text-shadow "
            style={{ fontFamily: "SuperNova", fontSize: "2rem",color:"#020c6b" }}
          >
            Privacy Policy
          </h3>

          <ToogleContent
            title="Information Collection and Use:"
            content=" We collect personal information, including names, email addresses,
              contact details, and messages, when users voluntarily submit it
              through our contact forms or other communication channels. The
              information collected is used for the purpose of responding to
              inquiries, providing requested services, and improving user
              experience. We may also use the provided email address to send
              occasional notifications, updates, or promotional materials
              related to our website or services. Users can opt-out of receiving
              such communications."
          />
          <ToogleContent
            title="Data Protection and Security:"
            content="  We take the privacy and security of your personal information
              seriously. We implement industry-standard measures to protect the
              data against unauthorized access, loss, or alteration. Access to
              personal information is restricted to authorized personnel only,
              and we ensure they are bound by strict confidentiality
              obligations."
          />
          <ToogleContent
            title=" Use of Personal Information:"
            content=" The personal information collected is used to address user
              inquiries, provide requested services or information, and enhance
              our website and services. We may analyze aggregated data to
              understand user trends and preferences, which helps us improve our
              offerings."
          />
          <ToogleContent
            title=" Third-Party Disclosure:"
            content=" We do not sell, trade, or transfer users' personal information to
              third parties without their consent, except as required by law. We
              may share personal information with trusted third-party service
              providers who assist us in operating our website or conducting our
              business, as long as they agree to maintain confidentiality."
          />
          <ToogleContent
            title="Information Collection and Use:"
            content=" We collect personal information, including names, email addresses,
              contact details, and messages, when users voluntarily submit it
              through our contact forms or other communication channels. The
              information collected is used for the purpose of responding to
              inquiries, providing requested services, and improving user
              experience. We may also use the provided email address to send
              occasional notifications, updates, or promotional materials
              related to our website or services. Users can opt-out of receiving
              such communications."
          />
          <ToogleContent
            title="GDPR Data Protection Rights"
            content="We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.

The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.

The right to erasure – You have the right to request that we erase your personal data, under certain conditions.

The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.

The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.

The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."
          />
        </div>
        {/* Cookies Policy section */}

        <div className="container" id="cookies">
          <h3
            className="text-start my-5 mb-4 fw-bold text-shadow "
            style={{ fontFamily: "SuperNova", fontSize: "2rem",color:"#020c6b" }}
          >
            Cookies Policy
          </h3>

          <ToogleContent
            title="Cookies and Tracking Technologies:"
            content="Our website uses cookies and similar tracking technologies to enhance user experience, analyze website traffic, and personalize content.
By using our website, users consent to the use of cookies. Users can manage their cookie preferences through their browser settings."
          />
          <ToogleContent
            title="Third-Party Links:"
            content="Our website may contain links to third-party websites or services that are not under our control.
We are not responsible for the privacy practices or content of those sites. Users should review the privacy policies of those third parties when accessing their websites."
          />
          <ToogleContent
            title="User Rights:"
            content="Users have the right to access, update, or delete their personal information held by us. To exercise these rights or inquire about their personal data, users can contact us using the provided contact information."
          />
          <ToogleContent
            title="Changes to the Privacy and Cookies Policy:"
            content="We reserve the right to update or modify this privacy and cookies policy as necessary. Users will be notified of any significant changes through prominent notices on our website or via email."
          />
          <ToogleContent
            title="Contact Information:"
            content="If you have any questions, concerns, or requests regarding this privacy and cookies policy, please contact our privacy officer at [insert contact email/address]."
          />
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
