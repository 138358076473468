import axios from "axios";
import React, { useState } from "react";
import { IoCall, IoChatboxEllipses, IoLocationSharp } from "react-icons/io5";
import "../assets/css/contacts.css";
import { CONTACT_URL } from "./apiConstants";
import { Helmet } from "react-helmet";
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    axios
      .post(CONTACT_URL, formData)
      .then((response) => {
        console.log(response.data);
        alert("Your message has been sent successfully");

        // Clear the form
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      })
      .catch((err) => {
        if (err.response) {
          // The request was made and the server responded with a status code
          // Extract the error message from the response data
          const errorMessage = err.response.data[0];
          alert(`Error: ${errorMessage}`);
        } else {
          // Error occurred before the request was made or no response was received
          alert("Sorry, something went wrong");
        }
      });
  };
  return (
    <>
    <Helmet>
        <title>Contact - ELiN </title>
      </Helmet>
      <section className=" d-flex  mt-md-5 mt-3 pt-md-5 justify-content-center  ">
        <div className="form contact-form p-5 shadow-lg ">
          <div className="contact-info p-3">
            <h2
              className="title fs-2 fw-bold"
              style={{ color: "rgb(8, 8, 69)", fontFamily: "superNova" }}
            >
              Let's get in touch
            </h2>
            <p className="text">
            Contact me by signing up with the following details.
            </p>
            <div className="info">
              <div className="social-information">
                <p
                  className="fs-3 fw-bold   "
                  style={{ color: "rgb(255, 123, 0)" }}
                >
                  <IoLocationSharp />
                </p>
                &emsp;
                <p> Greenhill City, Kageshwori Mannhara-06, Kathmandu </p>
              </div>
              <div className="social-information">
                <p
                  className="fs-3 fw-bold  "
                  style={{ color: "rgb(255, 123, 0)" }}
                >
                  <IoChatboxEllipses />
                </p>
                &emsp;
                <p>elin4np@gmail.com</p>
              </div>
              <div className="social-information">
                <p
                  className="fs-3 fw-bold  "
                  style={{ color: "rgb(255, 123, 0)" }}
                >
                  <IoCall />
                </p>
                &emsp;
                <p>+977-9861303199 </p>
              </div>
            </div>
            <div className="social-media ">
              <p >Connect with me :</p>
              <div className="social-icons ">
                <a
                  href="https://www.facebook.com/ELiNepal/"
                  target="_blank"
                  style={{ outline: "none" }}
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                
                <a
                  href="https://www.instagram.com/exquisitelearninginnepal/?fbclid=IwAR19TERyYnetLrSF9w8HxQtNyhBcO5gwoxMcVXuYqNL61b6mAV2xvPv1yJw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://www.linkedin.com/in/exquisite-learning-in-nepal-ba7a0a28a/">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
          </div>
          <div className="contact-info-form p-2">
            <span className="circle one" /> <span className="circle two" />
            <form action="#" autoComplete="off">
              <h3 className="title text-light fs-3 fw-semibold">Contact Me</h3>
              <div className="social-input-containers">
                <input
                  type="text"
                  name="name"
                  className="input"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="social-input-containers">
                <input
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="social-input-containers">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  placeholder="Mobile Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="social-input-containers textarea">
                <textarea
                  name="message"
                  className="input"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-sm-2 w-75 text-light "
                  style={{ backgroundColor: "rgb(255, 123, 0)" }}
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
