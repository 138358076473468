// on local machine
// export const API_URL = "http://localhost:8000/api/v1/";

// on server
export const API_URL = "https://admin.elin-ielts.com/api/v1/";

// required urls
export const COURSE_BY_CATEGORY_URL = `${API_URL}courses_category/`;
export const WRITING_URL = `${API_URL}writing/`;
export const READING_URL = `${API_URL}reading/`;
export const SPEAKING_URL = `${API_URL}speaking/`;
export const LISTENING_URL = `${API_URL}listening/`;
export const DETAILS_URL = `${API_URL}details/`;
export const ABOUT_URL = `${API_URL}about/`;
export const CONTACT_URL = `${API_URL}contact/`;
export const SUBSCRIBE = `${API_URL}subscribe/`;
export const GALLERY_URL = `${API_URL}galleries/`;
export const TREND_COURSES_URL = `${API_URL}trending/`;
export const TESTIMONIALS_URL = `${API_URL}testimonials/`;
