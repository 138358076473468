import axios from "axios";
import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link, useParams } from "react-router-dom";
import "../assets/css/list.css";
import { DETAILS_URL, TREND_COURSES_URL } from "./apiConstants";

function Viewpage() {
  const { slug } = useParams();
  const { pageName } = useParams();

  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get(`${DETAILS_URL}${pageName}/${slug}/`).then((res) => {
      setData(res.data);
    });
  }, [slug, pageName]);

  // recommended courses
  useEffect(() => {
    axios.get(TREND_COURSES_URL).then((res) => {
      setItems(res.data);
      // console.log(res.data);
    });
  }, [slug]);

  // console.log(items);
  // items.map((item)=>console.log(item.tags[0].name ))

  return (
    <>
      <div className="  d-flex flex-xl-nowrap flex-wrap ">
        <div className="container-fluid card-outer  my-5  ">
          <Card className="border-0 shadow-sm">
            <div className="text-center">
              <Card.Img
                variant="top"
                src={data.thumbnail}
                style={{ objectFit: "contain", height: "30em" }}
                className="img-small"
              />
            </div>
            <Card.Body className="mb-5 mx-3 cardbody">
              <Card.Title
                className="fw-bolder mt-5 mb-4 title"
                style={{
                  fontFamily: "Tazwan",
                  fontSize: "3.5em",
                  lineHeight: "3.8rem",
                }}
              >
                {data.title}
              </Card.Title>
              <Card.Text
                className="fs-5 text-justify "
                style={{ fontFamily: "Poopins" }}
              >
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="container-fluid my-xl-5 border border-5 background shadow trending">
          <h3 className="text-center my-5 mt-4 fs-3 fw-bold text-white border-bottom border-4 trending-text">
            Trending on ELiN's
          </h3>
          {!items.length ? (
            <div className="d-flex flex-wrap justify-content-center">
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <ListGroup>
              {items.map((item, index) => (
                <Link
                  to={`/details/${item.tags[0].name}/${item.slug}`}
                  key={index}
                  className="item-margin click"
                >
                  <ListGroup.Item>
                    <img
                      src={item.thumbnail}
                      style={{
                        width: "4rem",
                        height: "4rem",
                        objectFit: "contain",
                      }}
                      alt="Avatar"
                      className="avatar me-1"
                    />
                    <div className="content">
                      <p className="mt-2 fs-5 fw-semibold">{item.title}</p>
                    </div>
                    <div className="text-end">
                      <span className="date text-warning">
                        {item.created_at}
                      </span>
                    </div>
                  </ListGroup.Item>
                </Link>
              ))}
            </ListGroup>
          )}
        </div>
      </div>
    </>
  );
}

export default Viewpage;
