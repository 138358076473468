import React from 'react'
import { Helmet } from 'react-helmet'

function PageNotFound() {
  return (
    <div class=" container p-2 ">
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
    <h1 className='text-danger'>Page Not Found</h1> 
    <p>Oops! The page you are looking for might be under construction or has been moved. Please check the URL and try again.</p>
    <p>If you believe this is a mistake, you can go back to <a href="/">the homepage</a> or <a href="/contact">contact</a> my support team for assistance.</p>
</div>
  )
}

export default PageNotFound