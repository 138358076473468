import React from "react";
import { Button, Card } from "react-bootstrap";
import "../assets/css/terms.css";
import terms from "../assets/images/terms.jpg";
import { ToogleContent } from "./toogleContent";

function TermsCondition() {
  return (
    <>
      <div>
        <Card className="mt-1 shadow  border border-warning  border-5 text-white">
          <Card.Img src={terms} alt="Card image"  className="terms-img" style={{ height: "18rem" }} />
          <Card.ImgOverlay className="text-center mt-4">
            <Card.Title className="imgtitle  fw-bolder fs-1">
              Disclaimers & Terms and Conditions
            </Card.Title>
            <Card.Text className="fs-5">
              "Your Path to a Positive User Experience: Paving the Way with
              Reliable Information and Comprehensive Terms and Conditions."
            </Card.Text>
            <Button variant="warning m-2 text-white ">Learn more</Button>
          </Card.ImgOverlay>
        </Card>

        {/* disclaimers */}
        {/* terms and conditins */}
        {/* Privacy Policy section */}

        <div className="container" id="disclaimers">
          <h3
            className="text-start my-5 mb-3 fw-bold text-shadow "
            style={{
              fontFamily: "SuperNova",
              fontSize: "2rem",
              color: "#020c6b",
            }}
          >
            Disclaimers
          </h3>
          <p
            className="fs-5"
            style={{
              fontFamily: "Tajawal ",
            }}
          >
            The information provided on this website, including blog posts,
            articles, and other content related to IELTS, is for general
            informational purposes only. It is not intended to be a substitute
            for professional advice or guidance. While we strive to provide
            accurate and up-to-date information, we make no representations or
            warranties of any kind, express or implied, about the accuracy,
            completeness, reliability, or suitability of the information
            contained herein. <br />
            <br />
            By accessing and using this website, you acknowledge and agree that
            you do so at your own risk. We shall not be liable for any direct,
            indirect, incidental, consequential, or punitive damages or losses
            arising from your use of or reliance on the information provided on
            this website. This includes, but is not limited to, any errors or
            omissions in the content, or any loss or damage incurred as a result
            of using our services or accessing third-party links or content.{" "}
            <br />
            <br />
            Any opinions or views expressed in user-generated content, such as
            comments or forum posts, are those of the respective authors and do
            not necessarily reflect our views or opinions. <br />
            <br />
            This website may contain links to third-party websites or services.
            We do not have control over the content, accuracy, or availability
            of those websites. The inclusion of any links does not necessarily
            imply a recommendation or endorsement by us. Users are responsible
            for reviewing the terms and conditions and privacy policies of any
            third-party websites they visit.
            <br />
            <br />
            All trademarks, logos, or copyrighted materials displayed on this
            website are the property of their respective owners. Unauthorized
            use or reproduction of any intellectual property is strictly
            prohibited. <br /> <br />
            We reserve the right to update or modify this disclaimer as
            necessary and users are responsible for reviewing it periodically.
          </p>
        </div>
        {/* Cookies Policy section */}

        <div className="container" id="terms">
          <h3
            className="text-start my-5 mb-4 fw-bold text-shadow "
            style={{
              fontFamily: "SuperNova",
              fontSize: "2rem",
              color: "#020c6b",
            }}
          >
            Terms and Conditions
          </h3>

          <ToogleContent
            title="Intellectual Property::"
            content="All content on this website, including text, graphics, logos, images, audio clips, and software, is the property of [Your Company Name] or its licensors and is protected by applicable copyright, trademark, and other intellectual property laws.
You may not reproduce, distribute, modify, transmit, or use any of the website's content without obtaining explicit permission from [Your Company Name]."
          />
          <ToogleContent
            title="Use of Website:"
            content="You agree to use this website for lawful purposes and in a manner that does not infringe upon the rights of others or restrict their use and enjoyment of the website.
You will not engage in any activity that could cause damage to the website, impair its functionality, or interfere with the access and use of the website by others."
          />
          <ToogleContent
            title="User-Generated Content:"
            content="By submitting any content, including comments, feedback, or suggestions, to this website, you grant [Your Company Name] a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, and display the content in any media.
You are solely responsible for the content you submit and warrant that it does not violate any third-party rights, including copyright, trademark, privacy, or publicity rights."
          />
          <ToogleContent
            title="Links to Third-Party Websites:"
            content="This website may contain links to third-party websites or services that are not owned or controlled by [Your Company Name].
We do not endorse or assume any responsibility for the content, privacy policies, or practices of third-party websites. You access such websites at your own risk."
          />
          <ToogleContent
            title="Limitation of Liability:"
            content="To the fullest extent permitted by law, [Your Company Name] shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of, or inability to use, this website.
We make no warranties or representations, express or implied, regarding the accuracy, completeness, reliability, or suitability of the content on this website."
          />
          <ToogleContent
            title="Indemnification:"
            content="You agree to indemnify and hold harmless [Your Company Name], its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses (including attorneys' fees) arising out of or related to your use of the website or violation of these terms and conditions."
          />
          <ToogleContent
            title="Modification of Terms:"
            content="[Your Company Name] reserves the right to modify, update, or change these terms and conditions at any time without prior notice.
It is your responsibility to review these terms periodically to stay informed about any changes."
          />
          <ToogleContent
            title="Governing Law and Jurisdiction:"
            content="These terms and conditions shall be governed by and construed in accordance with the laws of [Your Country/State/Region].
Any disputes arising from or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction]."
          />
        </div>
      </div>
    </>
  );
}

export default TermsCondition;
