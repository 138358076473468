import React from "react";
 import "../assets/css/home.css";
import "../assets/css/testimonial.css";
import elinVideo from "../assets/images/elinVideo.mp4";
 
import Testimonials from "./testimonials";
import { Helmet } from "react-helmet";

function Home() {
  

  return (
    <>
      <div className="">
        <Helmet>
          <title>Home - ELiN </title>
        </Helmet>
        {/* Home video */}
        <div className="m-3 ms-0 me-0 p-0 ">
          <span className="">
            <video
              src= {elinVideo}
              class="object-fit-contain rounded border  shadow border-2"
              style={{ width: "100%", height: "50%" }}
              autoPlay
              loop
              muted
              playsinline
            ></video>
          </span>
        </div>

        {/* Testimonial */}
        <Testimonials />
      </div>
    </>
  );
}

export default Home;
