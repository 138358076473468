import React from "react";
import { Button, Card } from "react-bootstrap";
import "../assets/css/terms.css";
import terms from "../assets/images/terms.jpg";
import { ToogleContent } from "./toogleContent";

function Faqs() {
  return (
    <>
      <div>
        <Card className="mt-1 shadow  border border-warning  border-5 text-white">
          <Card.Img src={terms} alt="Card image" className="terms-img" style={{ height: "18rem" }} />
          <Card.ImgOverlay className="text-center mt-4">
            <Card.Title className=" fw-bolder fs-1 imgtitle">
              Frequently Asked Questions
            </Card.Title>
            <Card.Text className="fs-5">
              "Your Path to a Positive User Experience: Paving the Way with
              Reliable Information and Comprehensive Terms and Conditions."
            </Card.Text>
            <Button variant="warning m-2 text-white ">Read more</Button>
          </Card.ImgOverlay>
        </Card>

        {/* Frequents answer and question */}

        <div className="container">
          <h3
            className="text-start my-5 mb-4 fw-bold text-shadow "
            style={{
              fontFamily: "SuperNova",
              fontSize: "2rem",
              color: "#020c6b",
            }}
          >
            Frequently Asked Questions
          </h3>

          <ToogleContent
            title="What is IELTS?"
            content="IELTS (International English Language Testing System) is a standardized test that measures the language proficiency of individuals who want to study, work, or migrate to English-speaking countries."
          />
          <ToogleContent
            title="What are the different sections of the IELTS test?"
            content="The IELTS test consists of four sections: Listening, Reading, Writing, and Speaking. Each section assesses different language skills."
          />
          <ToogleContent
            title="How can I improve my IELTS Listening skills?"
            content="To improve your IELTS Listening skills, you can practice with sample tests, listen to English audio materials, and focus on note-taking techniques."
          />
          <ToogleContent
            title="What are some effective strategies for the IELTS Speaking test?"
            content="Some effective strategies for the IELTS Speaking test include practicing speaking in English regularly, familiarizing yourself with common topics, and organizing your responses coherently."
          />
          <ToogleContent
            title="How can I enhance my IELTS Reading comprehension?"
            content="To enhance your IELTS Reading comprehension, you can practice reading different types of texts, develop skimming and scanning techniques, and work on improving your vocabulary."
          />
          <ToogleContent
            title="What are the key elements to consider in IELTS Writing Task 1?"
            content="In IELTS Writing Task 1, key elements to consider include understanding the task requirements, organizing your response logically, and effectively using data and vocabulary."
          />
          <ToogleContent
            title="Are there any recommended resources for IELTS preparation?"
            content="There are various resources available for IELTS preparation, such as official IELTS practice materials, online practice tests, and study guides. It's important to choose reliable and reputable sources."
          />
          <ToogleContent
            title="Can I take IELTS multiple times?"
            content="Yes, you can take the IELTS test multiple times. There are no restrictions on the number of attempts, and you can choose to retake specific sections if needed."
          />
          <ToogleContent
            title="How is the IELTS test scored?"
            content="The IELTS test is scored on a scale of 0 to 9, with half-band increments. The scores reflect your proficiency level in each section as well as an overall band score."
          />
        </div>
      </div>
    </>
  );
}

export default Faqs;
