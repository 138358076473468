import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function CardComponent({ data, pageName }) {
  return (
    <div>
      <div className=" row row-cols-1 row-cols-lg-3 row-cols-md-2 mt-4 d-flex justify-content-around">
        {data.map((item) => (
          <Card className="mb-2   animate p-0" style={{ width: "23rem" }}>
            <Card.Img
              variant="top"
              style={{
                height: "15rem",
                width: "100%",
                objectFit: "contain",
              }}
              alt={item.title}
              src={item.thumbnail}
            />
            <Card.Body>
              <Card.Title
                className="fw-semibold"
                style={{ fontFamily: "poppins" }}
              >
                {item.title}
              </Card.Title>
              <Card.Text style={{ fontFamily: "super-nova" }}>
                <div>
                  {item.content &&
                    (item.content.length > 100 ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content.slice(0, 100) + "...",
                        }}
                      />
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    ))}
                </div>
              </Card.Text>
              <Link
                to={`/details/${pageName}/${item.slug}`}
                className="text-white text-decoration-none"
              >
                <Button variant="warning w-100">Read now..</Button>
              </Link>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default CardComponent;
