import axios from "axios";
import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../assets/css/testimonial.css";
import { TESTIMONIALS_URL } from "./apiConstants";

function Testimonials() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [testimonials, setTestomonials] = useState([]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    // Make an API request to fetch the course data
    axios
      .get(TESTIMONIALS_URL)
      .then((response) => {
        setTestomonials(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  return (
    <div className="container-fluid testimonial  mb-md-4" id="testimonials">
      <h2
        className="text-center my-5 fw-semibold text-shadow"
        style={{ fontFamily: "super-nova", color: "rgb(1,0,134)" }}
      >
        Testimonials
      </h2>

      <Carousel
        data-bs-theme="dark"
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={null} // Disable auto-slide
      >
        {testimonials.map((testimonial, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="testimonial-card">
                <img
                  className="logo-image mb-3 shadow"
                  src={testimonial.profile_pic}
                  alt={testimonial.name}
                />
                <h4 style={{ color: "rgb(1,0,134)" }} className="ps-3">
                  {testimonial.name}
                </h4>

                <p className="paragrap-content mx-md-5  px-md-5 px-sm-0 p-1 text-center">
                  {" "}
                  {testimonial.message}
                </p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Testimonials;
