import React, { useState } from "react";
import { BiHide } from "react-icons/bi";
import { FaBook } from "react-icons/fa";

export function ToogleContent({ title, content }) {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className="mx-0 border border-dark m-3 p-3">
        <div className="d-flex flex-wrap   justify-content-between align-items-center">
          <h4 className="" style={{ fontFamily: "Tajawal ", color: "#020c6b" }}>
            {title}
          </h4>
          <p
            className="fw-bold fs-4"
            onClick={toggleVisibility}
            style={{ cursor: "pointer", color: "#f37114" }}
          >
            {isVisible ? <BiHide className=" fs-2" /> : <FaBook />}
          </p>
        </div>
        <div className=" p-1 ">
          {isVisible && (
            <p className="fs-5" style={{ fontFamily: "Tajawal " }}>
              {content}
            </p>
          )}
        </div>
      </div>
    </>
  );
}
