import axios from "axios";
import React, { useEffect, useState } from "react";
import { LISTENING_URL } from "./apiConstants";
import CardComponent from "./card";
import Tab from "./tabs";
import { Helmet } from "react-helmet";

function Listening() {
  const [introduction, setIntroduction] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [practice, setPractice] = useState([]);

  useEffect(() => {
    // Make an API request to fetch the course data
    axios
      .get(LISTENING_URL)
      .then((response) => {
        const data = response.data;

        // Define category titles for different sections
        const categoryTitles = {
          Introduction: "Introduction",
          Strategies: "Strategies",
          Practice: "Practice",
        };

        // Create an object to store filtered data for each category
        const filteredData = {};

        // Filter data based on category titles
        Object.keys(categoryTitles).forEach((section) => {
          const categoryTitle = categoryTitles[section];
          const sectionData = data.filter((item) =>
            item.categories.some((category) => category.title === categoryTitle)
          );
          filteredData[section] = sectionData;
        });

        // Set the filtered data to the state variables
        setIntroduction(filteredData.Introduction);
        setStrategies(filteredData.Strategies);
        setPractice(filteredData.Practice);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  const listeningTabs = [
    {
      type: "Articles",
      content: <CardComponent data={introduction} pageName="listening" />,
    },
    {
      type: "Strategies",
      content: <CardComponent data={strategies} pageName="listening" />,
    },
    {
      type: "Practice",
      content: <CardComponent data={practice} pageName="listening" />,
    },
  ];

  const category = "listening";

  return (
    <div className="container-fluid ">
      <Helmet>
        <title>Listening - ELiN</title>
        <meta name="description" content="Listening - ELiN" />
      </Helmet>
      <Tab tabs={listeningTabs} category={category} />
    </div>
  );
}

export default Listening;
