import axios from "axios";
import React, { useEffect, useState } from "react";
import { WRITING_URL } from "./apiConstants";
import CardComponent from "./card";
import Tab from "./tabs";
import { Helmet } from "react-helmet";

function Writing() {
  const [introduction, setIntroduction] = useState([]);
  const [task1A, setTask1A] = useState([]);
  const [task1GT, setTask1GT] = useState([]);
  const [task2, setTask2] = useState([]);
  const [samples, setSamples] = useState([]);
  const [practice, setPractice] = useState([]);

  useEffect(() => {
    // Make an API request to fetch the course data
    axios
      .get(WRITING_URL)
      .then((response) => {
        const data = response.data;

        // Define category titles for different sections
        const categoryTitles = {
          Introduction: "Introduction",
          Task1A: "Task1A",
          Task1GT: "Task1GT",
          Task2: "Task2",
          Samples: "Samples",
          Practice: "Practice",
        };

        // Create an object to store filtered data for each category
        const filteredData = {};

        // Filter data based on category titles
        Object.keys(categoryTitles).forEach((section) => {
          const categoryTitle = categoryTitles[section];
          const sectionData = data.filter((item) =>
            item.categories.some((category) => category.title === categoryTitle)
          );
          filteredData[section] = sectionData;
        });

        // Set the filtered data to the state variables
        setIntroduction(filteredData.Introduction);
        setTask1A(filteredData.Task1A);
        setTask1GT(filteredData.Task1GT);
        setTask2(filteredData.Task2);
        setSamples(filteredData.Samples);
        setPractice(filteredData.Practice);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  const writingTabs = [
    {
      type: "Articles",ftype:"Articles",
      content: <CardComponent data={introduction} pageName="writing" />,
    },
    {
      type: "T1(A)",ftype:"Task1 (A)",
      content: <CardComponent data={task1A} pageName="writing" />,
    },
    {
      type: "T1(GT)",ftype:"Task1 (GT)",
      content: <CardComponent data={task1GT} pageName="writing" />,
    },
    {
      type: "T2", ftype:"Task2",
      content: <CardComponent data={task2} pageName="writing" />,
    },
    {
      type: "Samples",ftype:"Samples",
      content: <CardComponent data={samples} pageName="writing" />,
    },
    {
      type: "Practice",ftype:"Practice",
      content: <CardComponent data={practice} pageName="writing" />,
    },
  ];
  const writingsection = "writing";

  return (
    <div className="container-fluid">
      <Helmet >
        <title>Writing - ELiN</title>
        <meta
          name="description"
          content="ELiN - Writing Section - Articles, Task 1, Task 2, Samples, Practice"      />
      </Helmet>
      <Tab tabs={writingTabs} category={writingsection} />
    </div>
  );
}

export default Writing;
