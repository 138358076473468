import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AboutAuthor from "./frontend/aboutAuthor";
import Contact from "./frontend/contact";
import Faqs from "./frontend/faqs";
import Footer from "./frontend/footer";
import Home from "./frontend/home";
import Listening from "./frontend/listening";
import NavbarComponent from "./frontend/navbar";
import PrivacyPolicy from "./frontend/privacyPolicy";
import Reading from "./frontend/reading";
import Sidebar from "./frontend/sidebar";
import Speaking from "./frontend/speaking";
import TermsCondition from "./frontend/termsCondition";
import Viewpage from "./frontend/viewpage";
import Writing from "./frontend/writing";
import PageNotFound from "./frontend/pagenotFound";

function App() {
  return (
    <Router>
      <NavbarComponent />
      <div className="d-flex">
        <Sidebar />
        <div class="container-fluid  ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/speaking" element={<Speaking />} />
            <Route path="/reading" element={<Reading />} />
            <Route path="/writing" element={<Writing />} />
            <Route path="/listening" element={<Listening />} />
            <Route path="/about" element={<AboutAuthor />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsCondition />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="details/:pageName/:slug" element={<Viewpage />} />
             {/* 404 not found */}
            <Route path="*" element={<PageNotFound />} />

          </Routes>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
