import axios from "axios";
import React, { useEffect, useState } from "react";
import CardComponent from "./card";
import TabGroup from "./tabs";
import { READING_URL } from "./apiConstants";
import { Helmet } from "react-helmet";

function Reading() {
  const [introduction, setIntroduction] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [practice, setPractice] = useState([]);

  useEffect(() => {
    // Make an API request to fetch the course data
    axios
      .get(READING_URL)
      .then((response) => {
        const data = response.data;

        // Define category titles for different sections
        const categoryTitles = {
          Introduction: "Introduction",
          Strategies: "Strategies",
          Practice: "Practice",
        };

        // Create an object to store filtered data for each category
        const filteredData = {};

        // Filter data based on category titles
        Object.keys(categoryTitles).forEach((section) => {
          const categoryTitle = categoryTitles[section];
          const sectionData = data.filter((item) =>
            item.categories.some((category) => category.title === categoryTitle)
          );
          filteredData[section] = sectionData;
        });

        // Set the filtered data to the state variables
        setIntroduction(filteredData.Introduction);
        setStrategies(filteredData.Strategies);
        setPractice(filteredData.Practice);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  const readingTabs = [
    {
      type: "Articles",ftype:"Articles",
      content: <CardComponent data={introduction} pageName="reading" />,
    },
    {
      type: "Strategies",ftype:"Strategies",
      content: <CardComponent data={strategies} pageName="reading" />,
    },
    {
      type: "Practice",ftype:"Practice",
      content: <CardComponent data={practice} pageName="reading" />,
    },
  ];

  const readingsection = "reading";

  return (
    <div className="container-fluid ">
      <Helmet>
        <title>Reading - ELiN</title>
        <meta
          name="description"
          content="ELiN - Reading Section - Articles, Strategies, Practice"
        />
      </Helmet>
      <TabGroup tabs={readingTabs} category={readingsection} />
    </div>
  );
}
export default Reading;
