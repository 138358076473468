import axios from "axios";
import React, { useEffect, useState } from "react";
import { SPEAKING_URL } from "./apiConstants";
import CardComponent from "./card";
import Tab from "./tabs";
import { Helmet } from "react-helmet";

function Speaking() {
  const [introduction, setIntroduction] = useState([]);
  const [part1, setPart1] = useState([]);
  const [part2, setPart2] = useState([]);
  const [part3, setPart3] = useState([]);
  const [samples, setSamples] = useState([]);
  const [practice, setPractice] = useState([]);

  useEffect(() => {
    // Make an API request to fetch the course data
    axios
      .get(SPEAKING_URL)
      .then((response) => {
        const data = response.data;

        // Define category titles for different sections
        const categoryTitles = {
          Introduction: "Introduction",
          Part1: "Part1",
          Part2: "Part2",
          Part3: "Part3",
          Samples: "Samples",
          Practice: "Practice",
        };

        // Create an object to store filtered data for each category
        const filteredData = {};

        // Filter data based on category titles
        Object.keys(categoryTitles).forEach((section) => {
          const categoryTitle = categoryTitles[section];
          const sectionData = data.filter((item) =>
            item.categories.some((category) => category.title === categoryTitle)
          );
          filteredData[section] = sectionData;
        });

        // Set the filtered data to the state variables
        setIntroduction(filteredData.Introduction);
        setPart1(filteredData.Part1);
        setPart2(filteredData.Part2);
        setPart3(filteredData.Part3);
        setSamples(filteredData.Samples);
        setPractice(filteredData.Practice);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  const speakingTabs = [
    {
      type: "Articles", ftype:"Articles",
      content: <CardComponent data={introduction} pageName="speaking" />,
    },
    {
      type: "P1", ftype:"Part1",
      content: <CardComponent data={part1} pageName="speaking" />,
    },
    {
      type: "P2",ftype:"Part2",
      content: <CardComponent data={part2} pageName="speaking" />,
    },
    {
      type: "P3", ftype:"Part3",
      content: <CardComponent data={part3} pageName="speaking" />,
    },
    {
      type: "Samples",ftype:"Samples",
      content: <CardComponent data={samples} pageName="speaking" />,
    },
    {
      type: "Practice",ftype:"Practice",
      content: <CardComponent data={practice} pageName="speaking" />,
    },
  ];
  const speakingsection = "speaking";
  return (
    <div className="container-fluid ">
      <Helmet>
        <title>Speaking - ELiN</title>
        <meta
          name="description"
          content="ELiN - Speaking Section - Articles, Part1, Part2, Part3, Samples, Practice"      />

          
      </Helmet>
      <Tab tabs={speakingTabs} category={speakingsection} />
    </div>
  );
}

export default Speaking;
