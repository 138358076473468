// sidebar

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import React from "react";
import { FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import backgroundImage from "../assets/images/image.png";
import "../assets/css/sidebar.css";

const Sidebar = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "105vh",
        overflow: "scroll initial",
        marginTop: "0.1rem",
      }}
    >
      <CDBSidebar
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: "rgb(255,233,12)",
        }}
      >
        <CDBSidebarHeader
          className="  header-border"
          prefix={
            <i
              className="fa fa-bars fa-large "
              style={{ color: "rgb(1,0,134)" }}
            ></i>
          }
        >
          <NavLink
            exact
            to="/"
            className="text-decoration-none"
            style={{ color: "rgb(1,0,134)" }}
          >
            <FaHome /> Home
          </NavLink>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content ">
          <CDBSidebarMenu className="header-border">
            <NavLink exact to="/writing" activeClassName="activeClicked">
              <CDBSidebarMenuItem
                className="border-bottom border-white border-2"
                style={{ color: "rgb(1,0,134)" }}
                icon="pen"
              >
                Writing
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/listening" activeClassName="activeClicked">
              <CDBSidebarMenuItem
                className="border-bottom border-white border-2"
                icon="headphones"
                style={{ color: "rgb(1,0,134)" }}
              >
                Listening
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/reading" activeClassName="activeClicked">
              <CDBSidebarMenuItem
                style={{ color: "rgb(1,0,134)" }}
                className="border-bottom border-white border-2"
                icon="book"
              >
                Reading
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink
              exact
              to="/speaking"
              activeClassName="activeClicked"
              style={{ color: "rgb(1,0,134)" }}
            >
              <CDBSidebarMenuItem
                className="border-bottom border-white border-2"
                icon="volume-up"
                style={{ color: "rgb(1,0,134)" }}
              >
                Speaking
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/about" activeClassName="activeClicked">
              <CDBSidebarMenuItem
                className="border-bottom border-white border-2"
                icon="user"
                style={{ color: "rgb(1,0,134)" }}
              >
                Meet the Tutor
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/contact" activeClassName="activeClicked">
              <CDBSidebarMenuItem
                className=" "
                icon="phone"
                style={{ color: "rgb(1,0,134)" }}
              >
                Contact 
              </CDBSidebarMenuItem>
            </NavLink>
            {/* <a
              href=" https://wisdomedu.com.np"
              className="nav-link"
              target="blank"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem
                className="m-3 wisdom-menu-item"
                style={{ color: "rgb(1,0,134)" }}
              >
                Wisdom
              </CDBSidebarMenuItem>
            </a> */}
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
