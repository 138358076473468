import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import elinreact from "../assets/images/elinnobg.png";

function NavbarComponent() {
  return (
    <>
      <Navbar
        
        variant="light"
        className="shadow-lg mb-0 p-1"
        style={{
          backgroundColor: "whitesmoke",
          
        }}
      >
        <Navbar.Brand className="ms-1" as={Link} to="/">
          <span>
            <img
              alt=""
              src={elinreact}
              width="90"
              height="80"
              className="rounded-circle"
            />
          </span>
        </Navbar.Brand>
        <span>
          <h4
            className="fw-semibold mt-3 fs-2 "
            style={{ fontFamily: "Algerian", color: "rgb(1,0,134)" }}
          >
            Exquisite Learning In Nepal
          </h4>
        </span>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
