import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../assets/css/aboutAuthor.module.css";
import { ABOUT_URL, GALLERY_URL } from "./apiConstants";
import { Helmet } from 'react-helmet';

function AboutAuthor() {
  // api call to get author details

  const [about, setAbout] = useState();
  const [loading, setLoading] = useState(true);

  const [showAllPictures, setShowAllPictures] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [images, setImages] = useState([]);

  useEffect(() => {
    // Make an API request to fetch the images
    axios
      .get(GALLERY_URL)
      .then((response) => {
        // Extract the image URLs from the API response
        const imageUrls = response.data.map((gallery) => gallery.files).flat();
        // console.log(imageUrls);

        setImages(imageUrls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 2);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 2);
  };

  useEffect(() => {
    // Make an API request to fetch the About data
    axios
      .get(ABOUT_URL)
      .then((response) => {
        setAbout(response.data);
        // console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching About data:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }
  const profilePicUrl =
    about?.profile_pic ||
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const name = about?.name || "Name";

  // const qualification = about?.qualification || "Qualification";

  const address = about?.address || "Address";

  const message = about?.message || "Message";

  // const facebook = about?.facebook || "https://www.facebook.com/";

  // const twitter = about?.twitter || "https://twitter.com/";

  // const instagram = about?.instagram || "https://www.instagram.com/";

  // const linkedin = about?.linkedin || "https://www.linkedin.com/";

  // const tiktok = about?.tiktok || "https://www.tiktok.com/";

  return (
    <div className="container my-4">
      <Helmet>
        <title>Meet the Tutor - ELiN</title>
      </Helmet>
      <div className="col-md-8 mx-auto">
        {/* Profile widget */}
        <div className="bg-white shadow rounded overflow-hidden ">
          <div className={`px-4  ${styles.cover}`}>
            {/* Profile Info */}
            <div
              //  className={`${styles.profilehead}`}
              className="d-flex justify-content-center flex-column text-center mt-5 shadow-sm  "
            >
              <div className="profile me-3 ">
                <img
                  src={profilePicUrl}
                  alt={name}
                  style={{
                    objectFit: "contain",
                    height: "10rem",
                    width: "10rem",
                  }}
                  className="shadow-sm mb-4 img-thumbnail"
                />
              </div>
              <div className="media-body mb-3 " style={{color:"rgb(1,0,134)"}}>
                <h3 className="mt-0 mb-2 " >{name}</h3>

                {/* <h5 className="mt-0 mb-2">{qualification}</h5> */}

                <p className=" mb-4">
                  <i className="fas fa-map-marker-alt me-2" />
                  {address}
                </p>
              </div>
            </div>
          </div>
          {/* Social Buttons */}
          {/* <div className="bg-light mt-4 p-4 d-flex justify-content-end text-center">
            <section className=" ">
              <a
                className={`btn m-1 ${styles.btnSocial}`}
                href={facebook}
                role="button"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className={`btn m-1 ${styles.btnSocial}`}
                href={twitter}
                role="button"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                className={`btn m-1 ${styles.btnSocial}`}
                href={instagram}
                role="button"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                className={`btn m-1 ${styles.btnSocial}`}
                href={linkedin}
                role="button"
              >
                <i className="fab fa-linkedin-in" />
              </a>
              <a
                className={`btn m-1 ${styles.btnSocial}`}
                href={tiktok}
                role="button"
              >
                <i className="fab fa-tiktok" />
              </a>
            </section>
          </div> */}
          {/* About Section */}
          <div className="px-4 ">
            <div className="p-4 py-0 rounded shadow-sm bg-light">
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </div>
          </div>
          {/* Image Gallery */}
          <div className="py-4 px-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="mb-0">Recent photos</h5>
              <a
                href="#/"
                className="btn btn-link text-muted"
                onClick={() => setShowAllPictures(!showAllPictures)}
              >
                {showAllPictures ? "Show less" : "Show all"}
              </a>
            </div>
            <div className="row">
              {images.map((image, index) => {
                if (
                  (index === currentIndex || index === currentIndex + 1) &&
                  (showAllPictures || index < 2)
                ) {
                  return (
                    <div className="col-lg-6 mb-2" key={index}>
                      <img
                        src={image}
                        alt="Gallery_Image"
                        className="img-fluid rounded shadow-sm"
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
            {showAllPictures && images.length > 2 && (
              <div className="d-flex justify-content-center mt-3">
                {currentIndex !== 0 && (
                  <button className="btn btn-link me-2" onClick={handlePrev}>
                    <i className="fas fa-chevron-left" />
                  </button>
                )}
                {currentIndex + 2 < images.length && (
                  <button className="btn btn-link" onClick={handleNext}>
                    <i className="fas fa-chevron-right" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutAuthor;
